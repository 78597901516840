$alert-themes: (
    success: var(--success-secondary),
    error: var(--error-secondary),
);
$alert-internal-margin: $internal-space-multiplier * 3;
$alert-animation-duration: 0.3s;

@keyframes AlertAppear {
    from {
        transform: translate3d(0, -100%, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes AlertDisappear {
    from {
        transform: translate3d(0, 0, 0);
    }

    to {
        transform: translate3d(0, -100%, 0);
    }
}

%AlertStructure {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: $internal-space-multiplier * 7 $internal-space-multiplier * 8;
    z-index: 1;

    * {
        max-width: 1024px;
        margin: $alert-internal-margin auto;
    }

    .Alert__title {
        padding: 0;
        margin: 0;
        margin-bottom: $alert-internal-margin;
    }
}

%AlertSkin {
    background-color: var(--warning-secondary);
    color: var(--white);
    font-size: font-size('m');
    font-style: italic;
    box-shadow: 0 4px 4px 0 var(--shadow);
    animation: AlertAppear $alert-animation-duration;
    animation-fill-mode: forwards;

    &.is-disappearing {
        animation: AlertDisappear $alert-animation-duration;
        animation-fill-mode: forwards;
    }

    * {
        text-align: center;
    }

    // Overriding this as this molecule is the same for dark AND light mode with white color
    .Button {
        color: var(--white);
        outline-color: var(--white);
        background-color: transparent;
        outline: solid 1px var(--white);

        &:hover,
        &:focus,
        &:active {
            background-color: transparent;

            &:not(:disabled) {
                outline: solid 3px var(--white);
            }
        }

        // The secondary button
        &.Button--secondary {
            outline: 0;
        }

        &:hover {
            &:not(:disabled) {
                outline-color: var(--white);
            }
        }
    }
}

%AlertThemes {
    @each $label, $color in $alert-themes {
        &.Alert--#{$label} {
            background-color: $color;
        }
    }
}

.Alert {
    @extend %AlertStructure;
    @extend %AlertSkin;
    @extend %AlertThemes;
}
