/*
    GLOBAL MIXINS
*/

//   This is being used for adding quick css for various screen sizes. variables are in varibles.scss
//   Usage notes: this is to be used inside classes to add breakpoint specific styles as such -
//      Variable names are hypothetical
//     .body {
//         font-size: map-get($font-sizes, "s");
//
//         @include for-screen('xs') {
//             font-size: font-size(xs);
//         }
//     }
/*
XS: 375 > 575
SM 576 > 767
MD 768 > 991
LG 992 > 1199
XL 1200 > ∞ and beyond

These are the breakpoints that are being used in the design system for different screen designs
We use `min-width` to trigger these, so to use this mixin the dev will need to code mobile first

If you want to have styles for screen size - md, you'll need to add the styles normally in the class
and then add the breakpoint to add styles for how it should look above md.

eg. A button that needs to be red on md and green above that

.Button {
    background-color: red;

   // This will be triggered when the screen size is >='768px'
    @include for-screen('md') {
        background-color: green;
    }
}
 */
@mixin for-screen($breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
        @content;
    }
}

/*
    Prop options:
        Weight: extra-light | light | regular | bold
        Size: 4xl | 3xl | 2xl | xl | l | m | s | xs
        Style: normal | italic | oblique | initial | inherit
*/
@mixin font($weight: 'extra-light', $size: 's', $style: null) {
    font-weight: font-weight($weight);
    font-size: font-size($size);
    @if $style != null {
        font-style: $style;
    }
}
