%TextareaStructure {
    position: relative;
    display: inline-block;
    width: 100%;

    textarea {
        position: relative;
        width: inherit;
        min-height: 10rem;
        width: 100%;
        padding: $internal-space-multiplier * 2;
        padding-right: $internal-space-multiplier * 10;

        &:disabled,
        &:read-only {
            overflow: hidden;
        }
    }

    .Textarea__icon {
        position: absolute;
        top: -$internal-space-multiplier;
        right: 0;
    }
}

%TextareaSkin {
    textarea {
        @include font(light);
        background-color: var(--grey);
        color: var(--white);
        border: 0;
        border-radius: 0.4rem;

        &::placeholder {
            color: var(--grey-secondary);
        }

        &:disabled,
        &:read-only {
            color: var(--grey-secondary);
        }

        @at-root .Mode--light {
            // the grey colors need to be flipped for light mode
            textarea {
                --grey: #{$grey-100};
                --grey-secondary: #{$grey-300};
            }
        }

        // FF
        scrollbar-width: thin;

        // Webkit/Blink
        &::-webkit-scrollbar {
            width: 6px;
            background: var(--grey);
            height: 100%;
        }

        &::-webkit-scrollbar-thumb {
            background: rgba(0, 0, 0, 0.4);
            border-radius: 4px;
        }
    }

    .Textarea__icon {
        cursor: pointer;
    }
}

.Textarea {
    @extend %TextareaStructure;
    @extend %TextareaSkin;
}
