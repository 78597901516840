/*
    SETTINGS for the base of the entire system.
    This file targets only selectors and any base styles it requires in our system.\
*/
// Note: We use normalize.css to reboot the entire css in the project so this file contains only overrides and enhancements.\

@import 'node_modules/normalize.css/normalize';

:root {
    font-size: $base-font-size;
}

body,
html {
    @include font('light');
    font-family: $font-family-primary;
    line-height: $line-height-default;
    box-sizing: border-box;
    background-color: var(--primary);
    color: var(--secondary);
}

// Note: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
*,
*:before,
*:after {
    box-sizing: inherit;
}

a {
    text-decoration: none;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
        text-decoration: underline;
    }
}

button {
    padding: 0;
    border: none;
    color: inherit;
    /* show a hand cursor on hover; some argue that we
    should keep the default arrow cursor for buttons */
    cursor: pointer;
}

// To override user agent styles for autocomplete
// On combining the selectors for different browsers (-moz-, -webkit-) the code doesn't work for some reason
// Hence splitting the code, even though its majorly the same code
input:-moz-autofill,
input:-moz-autofill:hover,
input:-moz-autofill:focus {
    border: 0;
    caret-color: var(--secondary);
    box-shadow: 0 0 0px 1000px var(--primary) inset;
    border-bottom: 1px solid var(--secondary);
    -moz-text-fill-color: var(--secondary);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    border: 0;
    caret-color: var(--secondary);
    box-shadow: 0 0 0px 1000px var(--primary) inset;
    border-bottom: 1px solid var(--secondary);
    -webkit-text-fill-color: var(--secondary);
}

label {
    color: var(--secondary);
}

ul {
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

p {
    margin: 0;
}

.row {
    width: calc(100% + #{$grid-gutter-width});
}
