/*
  BUTTONS
*/
$button-transition-speed: 0.075s;

// Every component class we write should be divided into 3 abstract classes that in extends (use-case specific)
// This is done to have some level of separation of concerns
// TODO: Optimize the code in this approach

// 1. Structure
// This is the base skeleton of the component that will look ugly but will fit the structural style guide
// Holds properties like display, height/width, pseudo selectors or state related structural css
// ie - if this class isn't inherited, it might look themed, but not properly built
%ButtonStructure {
    display: block;
    height: 40px;
    padding: $internal-space-multiplier * 2 $internal-space-multiplier * 11;
    width: 100%;

    @include for-screen(md) {
        display: inline-block;
        width: auto;
    }
}

// 2. Skin
// This is the base theme of the component,
// that will lack the structure of the component but will apply the basis and the basic styles for the theming based on our designs
// Holds properties like font styles, border, margins, paddings, shadows etc
// ie - if this class isn't inherited the component might look ugly but still properly structured
%ButtonSkin {
    @include font(regular, s);
    line-height: $font-size-multiplier * 6;
    color: var(--primary);
    background-color: var(--secondary);
    outline: 0;
    transition: $button-transition-speed;
    text-align: center;

    &:hover,
    &:active,
    &:focus {
        &:not(:disabled) {
            outline: 0;
            box-shadow: none;
            background-color: var(--grey-secondary);
            transition: $button-transition-speed;
            text-decoration: none;
        }
    }

    &:disabled {
        background-color: var(--grey);
        color: var(--grey-secondary);
        cursor: $cursor-disabled;
    }
}

// 3. Themes
// This is the class that deals with all the variations for the component
// This only adds colours or some very superficial aspects depending on the theme
// i.e - if this class isn't inherited, user wont get themes for the component but a nice looking default button
%ButtonThemes {
    &.Button--secondary {
        background-color: transparent;
        color: var(--secondary);
        outline: solid 1px var(--secondary);
        transition: outline $button-transition-speed linear;

        &:hover,
        &:focus,
        &:active {
            // Need to add this here because in the ButtonSkin, on hover we change the bg color.
            background-color: transparent;

            &:not(:disabled) {
                outline: solid 3px var(--grey-secondary);
                transition: outline $button-transition-speed linear;
            }
        }

        &:disabled {
            cursor: $cursor-disabled;
            border-color: var(--grey);
            color: var(--grey-secondary);
        }
    }

    @at-root .Mode--light {
        .Button--secondary {
            --grey-secondary: #{$grey-300};
        }
    }
}

// Base class named accordingly
// This needs to be applied to whatever component wants to look like a button*

// * - it might not work that well with some selectors like `a` or `span` as they are inline components
//     hence those components might extend this and add some more structural code to it for this to work properly
.Button {
    @extend %ButtonStructure;
    @extend %ButtonSkin;
    @extend %ButtonThemes;

    &.Button--block {
        display: block;
        width: 100%;
    }
}
