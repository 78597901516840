// Added extra 6px to compensate grid gap for collapsed cells
$datepicker-day-cell-width: 40px;
$datepicker-text-color-default: var(--secondary);

%DatepickerStructure {
    position: relative;

    .Datepicker__calendar {
        display: none;
        padding: $internal-space-multiplier * 3;
        position: absolute;
        top: 5rem;
        right: 0;
        z-index: 9999;

        &.is-visible {
            display: block;
        }
    }

    .Datepicker__heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .Datepicker__arrow {
        line-height: 0;
        display: inline-block;
        width: $datepicker-day-cell-width;
    }

    .Datepicker__month {
        display: grid;
        grid-gap: 1px;
        grid-template-columns: repeat(7, 1fr);
        align-items: center;
        justify-items: center;
    }

    .Datepicker__day {
        width: $datepicker-day-cell-width;
        height: $datepicker-day-cell-width;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Datepicker__textfield {
        margin-bottom: 0;
    }
}

%DatepickerSkin {
    .Datepicker__input {
        .Icon {
            transition: transform 0.3s ease-in-out;
            margin: 0;
        }

        &.is-active {
            .Icon {
                transform: rotate(180deg);
            }
        }
    }

    .Datepicker__calendar {
        background: var(--grey-secondary);
    }

    .Datepicker__heading {
        margin-bottom: $internal-space-multiplier * 2;
        .Icon {
            cursor: pointer;
        }
    }

    .Datepicker__headingText {
        color: $datepicker-text-color-default;
    }

    .Datepicker__day {
        color: $datepicker-text-color-default;
        box-shadow: 0 0 0 1px var(--secondary);
        cursor: pointer;

        &.is-current {
            color: $datepicker-text-color-default;
            background: var(--primary);
        }

        &:hover:not(.is-disabled),
        &.is-selected {
            color: var(--primary);
            background: var(--secondary);
        }

        &.is-disabled {
            color: var(--grey);
            cursor: $cursor-disabled;
        }
    }
}

.Datepicker {
    @extend %DatepickerStructure;
    @extend %DatepickerSkin;
}
