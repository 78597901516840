%DisplayFlexCenter {
    display: flex;
    align-items: center;
}

%DraggableCardStructure {
    display: flex;
    flex-wrap: no-wrap;
    position: relative;
    width: 100%;

    .DraggableCard__body {
        @extend %DisplayFlexCenter;
        padding: $internal-space-multiplier $internal-space-multiplier * 3;
        flex-wrap: wrap;
        flex-basis: 90%;
    }
}

%DraggableCardSkin {
    background: var(--grey);
    border-radius: 0.4rem;
}

// For the leftmost drag area that the user will click to drag
%DraggableCardHandleStructure {
    @extend %DisplayFlexCenter;
    padding: $internal-space-multiplier * 3 $internal-space-multiplier;
}
%DraggableCardHandleSkin {
    background-color: var(--success-secondary);
    cursor: pointer;
    // To-Do: It doesn't get the border-radius without this, why??
    border-radius: inherit;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

// For the rightmost element,
// where we have a close button but we can also have some menu/options in the future (like edit etc)
%DraggableCardOptionsSkin {
    padding: $internal-space-multiplier * 4 $internal-space-multiplier;
    cursor: pointer;
}
%DraggableCardOptionsStructure {
    @extend %DisplayFlexCenter;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
}

.DraggableCard {
    @extend %DraggableCardStructure;
    @extend %DraggableCardSkin;
}

.DraggableCard__handle {
    @extend %DraggableCardHandleStructure;
    @extend %DraggableCardHandleSkin;

    .Icon {
        fill: var(--white);
    }
}

.DraggableCard__options {
    @extend %DraggableCardOptionsSkin;
    @extend %DraggableCardOptionsStructure;
}

.ReorderList {
    width: 100%;
}
