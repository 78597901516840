/*
    GLOBAL UTILITY CLASSES
*/

/*
    SPACES:
    Reference: https://app.zeplin.io/project/5d6646dc07b3b69b66d736b6/screen/5d935a89bcf7fd15ed1f097d

    20px is minimum allowed space between vertical elements
    4px - as the horizontal space should be divisible by 4 and it shouldn't go below 12px
*/
$vertical-space-multiplier: 2rem;
$horizontal-space-multiplier: 0.4rem;

$y-axis: (
    0: $vertical-space-multiplier * 0,
    1: $vertical-space-multiplier * 1,
    2: $vertical-space-multiplier * 2,
    3: $vertical-space-multiplier * 3,
    4: $vertical-space-multiplier * 4,
    5: $vertical-space-multiplier * 5,
);

// the '1' here is being multiplied by 3 as the minimum for vertical spaces is 12px and then the multiplying number keeps going up by 1.
// ie 4,5,6,7.
$x-axis: (
    0: $horizontal-space-multiplier * 0,
    1: $horizontal-space-multiplier * 3,
    2: $horizontal-space-multiplier * 4,
    3: $horizontal-space-multiplier * 5,
    4: $horizontal-space-multiplier * 6,
    5: $horizontal-space-multiplier * 7,
);

// If theres another space strength that is needed to be added, eg. 6,
// This is the variable that controls the generation of the class for that strength
// The variables $y-axis and $x-axis are the core, in which you'll need to define the '6' for each
// and the $spaces variable will allow the loop below to generate classes for the 6th strength
$spaces: 0, 1, 2, 3, 4, 5;

/**
 * Generate a spacing class (margin or padding) normal + for all breakpoints
 * $type: m|p
 * $direction: t|b|l|r|x|y|''
 * $strength: 0|1|2|3|4|5
 */
@mixin spacingClass($type, $direction, $strength) {
    .#{$type}#{$direction}--#{$strength} {
        @content;
    }

    @each $breakpoint, $_ in $breakpoints {
        .#{$type}#{$direction}--#{$breakpoint}-#{$strength} {
            @include for-screen($breakpoint) {
                @content;
            }
        }
    }
}

// This uses the variable to loop n times and utilizes the $y-axis and $x-axis maps to get correct values for the code
@each $strength in $spaces {
    @include spacingClass(m, '', $strength) {
        margin: map-get($y-axis, $strength) map-get($x-axis, $strength) !important;
    }

    @include spacingClass(m, t, $strength) {
        margin-top: map-get($y-axis, $strength) !important;
    }

    @include spacingClass(m, b, $strength) {
        margin-bottom: map-get($y-axis, $strength) !important;
    }

    @include spacingClass(m, l, $strength) {
        margin-left: map-get($x-axis, $strength) !important;
    }

    @include spacingClass(m, r, $strength) {
        margin-right: map-get($x-axis, $strength) !important;
    }

    @include spacingClass(m, y, $strength) {
        margin-top: map-get($y-axis, $strength) !important;
        margin-bottom: map-get($y-axis, $strength) !important;
    }

    @include spacingClass(m, x, $strength) {
        margin-right: map-get($x-axis, $strength) !important;
        margin-left: map-get($x-axis, $strength) !important;
    }
}
