/*
  Chip
*/
$chip-border-radius: 1.2rem;

%ChipLabel {
    padding: $internal-space-multiplier $internal-space-multiplier
        $internal-space-multiplier $internal-space-multiplier * 2;
}

%ChipIcon {
    border-radius: 0 $chip-border-radius $chip-border-radius 0;
    width: 24px;
    overflow: hidden;

    .Icon--close {
        position: absolute;
        top: -0.8rem;
        right: -0.6rem;
        fill: var(--primary);
    }

    &:hover {
        background-color: var(--error-secondary);
        cursor: pointer;

        .Icon--close {
            fill: var(--white);
        }
    }
}

%ChipStructure {
    display: inline-flex;
    height: 24px;
    position: relative;
    overflow: hidden;
}

%ChipSkin {
    @include font(regular, xs);
    color: var(--primary);
    background-color: var(--secondary);
    outline: 0;
    border-radius: $chip-border-radius;
}

.Chip {
    @extend %ChipStructure;
    @extend %ChipSkin;

    .Chip__label {
        @extend %ChipLabel;
    }

    .Chip__icon {
        @extend %ChipIcon;
    }
}
