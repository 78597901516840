/*
    Radio styles
    ------------
    For - Switch and Pagination or any other component in the system that acts like a radio group

    The term 'Radio' is being used loosely here as we had a hard time coming up with the 'perfect' name.
    Here, the term radio basically means a behaviour where in a list only one item can be selected at a time,

*/

@mixin radioStructure($componentName) {
    .#{$componentName} {
        display: block;
        list-style: none;
        display: inline-block;
        padding-left: 0;

        &__item {
            float: left;
            margin: auto 0.8rem;
            border: 1px solid transparent;
        }
    }
}

@mixin radioSkin($componentName) {
    .#{$componentName} {
        color: var(--grey-secondary);

        &__item {
            font-weight: font-weight(regular);
            cursor: pointer;
            transition: 0.2s border ease;

            &:hover,
            &:active,
            &:focus {
                border-color: var(--grey-secondary);
                transition: 0.2s border ease;
            }

            &.is-active {
                border-color: var(--secondary);
                color: var(--secondary);
                transition: 0.2s border ease;
            }
        }
    }

    @at-root .Mode--light {
        .#{$componentName} {
            --grey-secondary: #{$grey-300};
        }
    }
}
