$sidebar-width: 250px;
$sidebar-item-interaction-bg: var(--grey);
$sidebar-transition: 0.2s ease left;

%SidebarStructure {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -$sidebar-width;
    height: 100vh;
    width: $sidebar-width;
    padding-top: $internal-space-multiplier * 4;
    background-color: var(--grey-secondary);

    @include for-screen('lg') {
        background-color: var(--primary);
        position: relative;
        width: 100%;
        min-width: $sidebar-width;
        left: 0;
        margin-top: 0;
        padding-top: 0;
    }

    .Sidebar__list {
        list-style: none;
    }

    .Sidebar__listItem {
        margin-bottom: $internal-space-multiplier * 5;
        margin-right: 0;

        &--backBtn {
            padding-left: $internal-space-multiplier * 3;
            display: flex;
            align-items: center;

            @include for-screen('lg') {
                padding-left: 0;
            }
        }

        &--withoutLink,
        a {
            padding: $internal-space-multiplier * 2 $internal-space-multiplier *
                5;

            @include for-screen('lg') {
                padding: $internal-space-multiplier * 2;
            }
        }
    }
}

%SidebarSkin {
    transition: $sidebar-transition;

    .Sidebar__listItem {
        color: var(--secondary);
        cursor: pointer;
        line-height: 2.4rem;

        a {
            color: currentColor;
            text-decoration: none;
            display: block;
            width: 100%;
        }

        &:hover,
        &:focus,
        &:active {
            background-color: $sidebar-item-interaction-bg;
        }

        &.is-active {
            background-color: $sidebar-item-interaction-bg;
        }
    }
}

%SidebarCloseStructure {
    display: flex;
    height: 3rem;
    z-index: 10;
    margin-bottom: $internal-space-multiplier * 5;
    margin-top: $internal-space-multiplier * 10;
    align-items: center;

    @include for-screen('lg') {
        display: none;
    }

    .Icon {
        margin: $internal-space-multiplier * 3;
        cursor: pointer;
    }
}

%SidebarCloseSkin {
    .Icon--close {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.Sidebar__close {
    @extend %SidebarCloseStructure;
    @extend %SidebarCloseSkin;
}

.Sidebar {
    @extend %SidebarStructure;
    @extend %SidebarSkin;

    &.is-visible {
        transition: $sidebar-transition;
        left: 0%;
        z-index: 2;
        overflow: auto;
    }
}
