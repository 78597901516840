.DefaultLayout {
    display: grid;

    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
        'header'
        'content';
    row-gap: $internal-space-multiplier * 8;

    padding-top: $internal-space-multiplier * 12;
    padding-bottom: $internal-space-multiplier * 8;
    padding-left: $internal-space-multiplier * 4;
    padding-right: $internal-space-multiplier * 4;

    margin: 0 auto;

    @include for-screen(md) {
        max-width: 650px + $grid-gutter-width;
    }

    @include for-screen(lg) {
        max-width: 680px + 230px + $grid-gutter-width;

        grid-template-columns: auto 1fr;
        grid-template-rows: auto auto;
        grid-template-areas:
            'header header'
            'sidebar content';
        row-gap: $internal-space-multiplier * 14;
        column-gap: $internal-space-multiplier * 10;

        padding-top: $internal-space-multiplier * 10;
    }

    @include for-screen(xl) {
        max-width: 870px + 230px + $grid-gutter-width;
    }
}

.DefaultLayout__header {
    grid-area: header;
}

.DefaultLayout__sidebar {
    grid-area: sidebar;
}

.DefaultLayout__content {
    @extend %ContentLayoutStructure;
    grid-area: content;
}
