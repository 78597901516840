/*
    MODES
    NOTE: These css variables for Modes should be derived from the SCSS color variables list.
          Avoid having hardcoded color values here

    Modes - Default | Light
*/

// Light Mode
.Mode--light {
    // Overriding color variables for light theme
    --primary: #{$white};
    --secondary: #{$blue-900};

    --grey: #{$grey-300};
    --grey-secondary: #{$grey-100};

    --warning: #{$orange-300};
    --warning-secondary: #{$orange-600};

    --error: #{$red-300};
    --error-secondary: #{$red-600};

    --success: #{$green-300};
    --success-secondary: #{$green-800};
}
