%TableStructure {
    border-collapse: collapse;
    display: table;
    width: 100%;

    th,
    td {
        height: 45px;
        padding-right: $internal-space-multiplier * 3;
    }

    thead th {
        .Icon {
            vertical-align: middle;
        }
    }
}

%TableSkin {
    thead th {
        @include font(bold, xs);
    }

    th,
    td {
        border-bottom: 1px solid var(--grey-secondary);
        text-align: left;
    }
}

.Table {
    @extend %TableStructure;
    @extend %TableSkin;
}
