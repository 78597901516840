/*
    TEXTFIELD
*/
$textfield-container-height: 3rem;
$textfield-container-top-padding: $internal-space-multiplier * 4;
$textfield-font-size: 's';
$textfield-border-width: 1px;
$textfield-border-width-focused: 3px;
$textfield-vertical-padding: $internal-space-multiplier;
$textfield-horizontal-padding: $internal-space-multiplier * 2;

$textfield-themes: (
    success: (
        bg: var(--success),
        border: var(--success-secondary),
    ),
    warning: (
        bg: var(--warning),
        border: var(--warning-secondary),
    ),
    error: (
        bg: var(--error),
        border: var(--error-secondary),
    ),
);

// Styles for the placeholder when the Textfield is clicked or has value
%FloatingPlaceholder {
    font-weight: font-weight(regular);
    font-size: font-size(xs);
    top: 2px;
    opacity: 1;
}

%TextfieldStructure {
    display: block;
    position: relative;
    width: 100%;

    .Textfield__input {
        display: block;
        padding: $textfield-vertical-padding + $textfield-container-top-padding
            $textfield-horizontal-padding $textfield-vertical-padding;
        height: 100%;
        width: 100%;
    }

    .Textfield__label {
        display: block;
        position: absolute;
        pointer-events: none;
        left: $textfield-horizontal-padding;
        top: $textfield-container-top-padding;
    }

    .Textfield__hint {
        display: block;
        margin-top: $internal-space-multiplier;
        padding: 0 $internal-space-multiplier * 3;
    }
}

%TextfieldSkin {
    color: var(--secondary);

    .Textfield__input {
        @include font(light, $textfield-font-size);
        border: none;
        border-bottom: $textfield-border-width solid var(--secondary);
        transition: 0.3s all ease-in-out;

        background-color: transparent;
        color: var(--secondary);

        &::placeholder {
            color: transparent;
        }

        &:not(:disabled) {
            &:focus {
                background-color: var(--grey-secondary);

                // When the field received focus, the border should get thicker
                // To prevent visual jumping, we then reduce the bottom padding so that the
                // border grows "inward" but the full height of the field stays the same
                border-bottom-width: $textfield-border-width-focused;
                padding-bottom: calc(
                    #{$textfield-vertical-padding} - #{$textfield-border-width-focused -
                        $textfield-border-width}
                );
            }

            &:focus,
            &:not(:placeholder-shown) {
                outline: 0;

                & ~ .Textfield__label {
                    @extend %FloatingPlaceholder;
                }
            }
        }

        &:disabled {
            border-color: var(--grey);
            color: var(--grey);
            cursor: $cursor-disabled;

            &:not(:placeholder-shown) {
                & ~ .Textfield__label {
                    @extend %FloatingPlaceholder;
                }
            }

            & ~ .Textfield__label {
                color: var(--grey);
            }

            & + .Icon {
                fill: var(--grey);
            }
        }

        &:read-only {
            &:placeholder-shown {
                & ~ .Textfield__label {
                    font-size: font-size($textfield-font-size);
                    left: $textfield-horizontal-padding;
                    top: $textfield-container-top-padding;
                }
            }
        }

        & + .Icon {
            position: absolute;
            right: 0;
            top: 5px;
        }
    }

    .Textfield__label {
        color: var(--secondary);
        transition: 0.2s ease all;
        font-weight: font-weight(light);
        opacity: 0.7;
    }

    .Textfield__hint {
        @include font(light, xs, italic);
    }

    @at-root .Mode--light {
        .Textfield {
            --grey-secondary: #{$grey-300};
        }
    }
}

%TextfieldThemes {
    @each $theme, $style in $textfield-themes {
        &.Textfield--#{$theme} {
            .Textfield__input {
                background-color: map-get($style, bg);
                border-color: map-get($style, border);

                &:not(:disabled):focus {
                    // Keep themed background color on focus instead of the default
                    background-color: map-get($style, bg);
                }

                &:-moz-autofill,
                &:-moz-autofill:hover,
                &:-moz-autofill:focus {
                    box-shadow: 0 0 0px 1000px map-get($style, bg) inset;
                    border-bottom: 1px solid map-get($style, border);
                }

                &:-webkit-autofill,
                &:-webkit-autofill:hover,
                &:-webkit-autofill:focus {
                    box-shadow: 0 0 0px 1000px map-get($style, bg) inset;
                    border-bottom: 1px solid map-get($style, border);
                }
            }
        }
    }
}

.Textfield {
    @extend %TextfieldStructure;
    @extend %TextfieldSkin;
    @extend %TextfieldThemes;
}
