$dropdown-element-transition: 0.3s all ease;

// These variables are just here to make the `max-height` calculation clearer
$dropdown-font-size: $font-size-default;
$dropdown-line-height: $line-height-default;
$dropdown-text-height: $dropdown-font-size * $dropdown-line-height;
$dropdown-item-border-width: 1px;
$dropdown-item-padding-y: $internal-space-multiplier * 2;
$dropdown-item-height: $dropdown-text-height + $dropdown-item-padding-y * 2 +
    pxToRem($dropdown-item-border-width);

%DropdownStructure {
    position: relative;
    width: 100%;

    .Dropdown__label {
        .Dropdown__textfield {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-bottom: 0;

            .Icon {
                transform: rotate(180deg);
            }
        }
    }

    .Dropdown__list {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0;
        padding: 0;
        // Limit to 4.5 visible items for long lists
        max-height: $dropdown-item-height * 4.5;
        overflow: auto;

        .Dropdown__item {
            width: 100%;
            padding: $dropdown-item-padding-y $internal-space-multiplier * 3;
        }
    }
}

%DropdownSkin {
    background: transparent;
    color: var(--secondary);

    .Icon {
        transition: $dropdown-element-transition;
    }

    .Dropdown__label {
        // To override some styles from Textfield to fit the dropdown
        .Dropdown__textfield {
            cursor: pointer;
            min-width: auto;

            .Textfield__input {
                cursor: pointer;
            }
            .Textfield__input:read-only:placeholder-shown ~ .Textfield__label {
                font-weight: font-weight(light);
            }
        }

        &.is-active {
            background-color: var(--grey-secondary);
            color: var(--secondary);

            .Textfield {
                .Icon {
                    transform: rotate(0deg);
                }
            }
        }
    }

    .Dropdown__list {
        opacity: 0;
        visibility: hidden;
        list-style-type: none;
        background-color: var(--grey);
        transition: $dropdown-element-transition;

        &.is-visible {
            opacity: 1;
            visibility: visible;
            z-index: 2;
            box-shadow: 2px 4px 4px 0 var(--shadow);
        }

        .Dropdown__item {
            background-color: transparent;
            border-bottom: $dropdown-item-border-width solid var(--secondary);
            font-weight: font-weight(light);

            cursor: pointer;
            &:hover,
            &:active {
                color: var(--primary);
                background-color: var(--secondary);
            }
        }
    }
}

.Dropdown {
    @extend %DropdownStructure;
    @extend %DropdownSkin;
}
