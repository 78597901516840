%CardStructure {
    padding: $internal-space-multiplier * 2 $internal-space-multiplier * 3;

    .Card__body {
        margin-top: $internal-space-multiplier * 5;
    }

    &.Card--cta {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

%CardSkin {
    color: var(--secondary);
    background-color: var(--grey);
    border-radius: 0.4rem;

    .Card__label {
        @include font(regular, xs);
    }

    &.is-disabled {
        color: var(--grey-secondary);

        * {
            opacity: 0.75;
            pointer-events: none;
        }
    }

    @at-root .Mode--light {
        .Card {
            // The designs arent that direct. We need to adjust the colors a bit for the card only
            --grey: #{$grey-100};
            --grey-secondary: #{$grey-300};
        }
    }
}

.Card {
    @extend %CardStructure;
    @extend %CardSkin;
}
