.TwoColumnLayout {
    display: grid;
    grid-gap: map-get($y-axis, 1);
    align-content: start;
    align-items: self-start;
    width: 100%;
    --template-columns: 1fr 1fr;

    // Default to two columns on md size when no other modifier is added
    &:not(.TwoContentLayout--sm):not(.TwoColumnLayout--lg) {
        @include for-screen(md) {
            grid-template-columns: var(--template-columns);
            grid-gap: $grid-gutter-width;
        }
    }
}

.TwoColumnLayout--sm {
    @include for-screen(sm) {
        grid-template-columns: var(--template-columns);
        grid-gap: $grid-gutter-width;
    }
}

.TwoColumnLayout--lg {
    @include for-screen(lg) {
        grid-template-columns: var(--template-columns);
        grid-gap: $grid-gutter-width;
    }
}

.TwoColumnLayout--2-1 {
    --template-columns: 2fr 1fr;
}

.TwoColumnLayout--1-2 {
    --template-columns: 1fr 2fr;
}

.TwoColumnLayout--alignEnd {
    align-items: end;
}
