.Header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include for-screen(lg) {
        align-items: flex-end;
    }
}

.Header__heading {
    display: none;
    line-height: 1; // make sure this aligns properly on the bottom without extra space

    @include for-screen(lg) {
        display: block;
    }
}

.Header__menuTrigger {
    position: relative;
    height: 40px; // for some reason otherwise this is 3px higher than the icon?
    transform: translateX(-9px); // offset for icon whitespace

    @include for-screen(lg) {
        display: none;
    }
}

.Header__userMenu {
    transform: translateX(6px); // offset for icon whitespace

    @include for-screen(lg) {
        transform: translateX(0);
    }
}
