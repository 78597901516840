$switch-border-transition: 0.2s border ease;

%SwitchStructure {
    list-style: none;
    display: flex;
    justify-content: center;
    width: 100%;

    .Switch__item {
        margin: 0 $internal-space-multiplier * 2;
    }

    .Switch__input {
        display: none;
    }

    .Switch__label {
        display: block;
        padding: $internal-space-multiplier * 3 $internal-space-multiplier * 6;
    }
}

%SwitchSkin {
    color: var(--grey-secondary);

    .Switch__input {
        &:checked + .Switch__label {
            border-color: var(--secondary);
            color: var(--secondary);
            transition: $switch-border-transition;
        }
    }

    .Switch__label {
        cursor: pointer;
        font-weight: font-weight(regular);
        transition: $switch-border-transition;
        border: 1px solid transparent;
        color: var(--grey-secondary);

        &:hover {
            border-color: var(--grey-secondary);
        }
    }
}

.Switch {
    @extend %SwitchStructure;
    @extend %SwitchSkin;
}

.Mode--light {
    .Switch {
        // This is to change the default value to match the correct one as per design in the Light mode (Just for .Switch)
        --grey-secondary: #{$grey-300};
    }
}
