$label-themes: (
    success: var(--success-secondary),
    warning: var(--warning-secondary),
    error: var(--error-secondary),
);

%LabelStructure {
    display: inline-block;
    padding: $internal-space-multiplier * 2;
}

%LabelSkin {
    @include font(regular, xs);
    color: var(--grey-secondary);
    background-color: var(--grey);
}

%LabelTheme {
    @each $name, $color in $label-themes {
        &.Label--#{$name} {
            color: var(--white);
            background: $color;
        }
    }
}

.Label {
    @extend %LabelStructure;
    @extend %LabelSkin;
    @extend %LabelTheme;
}
