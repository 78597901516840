$heading-styles: (
    1: (
        size: 4xl,
        weight: extra-light,
        line-height: $font-size-multiplier * 13,
    ),
    2: (
        size: 3xl,
        weight: extra-light,
        line-height: $font-size-multiplier * 11,
    ),
    3: (
        size: 2xl,
        weight: light,
        line-height: $font-size-multiplier * 10,
    ),
    4: (
        size: xl,
        weight: extra-light,
        line-height: $font-size-multiplier * 9,
    ),
    5: (
        size: l,
        weight: regular,
        line-height: $font-size-multiplier * 7,
    ),
    6: (
        size: m,
        weight: regular,
        line-height: $font-size-multiplier * 6,
    ),
);

%HeadingSkin {
    // Default color for heading is secondary grey according to design
    color: var(--grey-secondary);

    @each $heading, $styles in $heading-styles {
        // HEADING SELECTOR STYLES
        @at-root h#{$heading},
            &.Heading--#{$heading} {
            @include font(map-get($styles, weight), map-get($styles, size));
            line-height: map-get($styles, line-height);
        }
    }

    @at-root .Mode--light {
        .Heading {
            // In light mode color is the same as the default text color
            --grey-secondary: var(--secondary);
        }
    }
}

.Heading {
    @extend %HeadingSkin;
}
