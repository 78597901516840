$icon-size: 40px !default;
$icon-size-small: 24px;
$icon-border-radius: 50%;

%IconStructure {
    display: inline-block;
    line-height: 1;

    width: $icon-size;
    height: $icon-size;
}

%IconSkin {
    fill: var(--secondary);
}

.Icon {
    @extend %IconStructure;
    @extend %IconSkin;

    &.Icon--add,
    &.Icon--add_small {
        fill: var(--primary);
        background: var(--secondary);
        border-radius: $icon-border-radius;
    }

    &.Icon--add_small {
        width: $icon-size-small;
        height: $icon-size-small;
    }

    &.Icon--add_disabled {
        background: var(--grey-secondary);
        fill: var(--grey);
        border-radius: $icon-border-radius;
    }

    &.Icon--button {
        cursor: pointer;
    }
}

.Mode--light {
    .Icon {
        --grey-secondary: #{$grey-600};
    }
}
