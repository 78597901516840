$checkbox-color-disabled: var(--grey-secondary);
$checkmark-border: 2px solid;
$checkmark-size: 20px;

// Checkmark container
%CheckmarkBoxStructure {
    content: '';
    margin-right: 0.5rem;
    display: inline-block;
    width: $checkmark-size;
    height: $checkmark-size;
    flex-shrink: 0;
}

%CheckmarkBoxSkin {
    background: transparent;
    border: 1px solid var(--grey);
}

%CheckmarkBox {
    @extend %CheckmarkBoxStructure;
    @extend %CheckmarkBoxSkin;
}

// Styles for the Checkmark (input:checked + label:after)
%CheckmarkSkin {
    border-left: $checkmark-border;
    border-bottom: $checkmark-border;
    border-color: $blue-900;
}

%CheckmarkStructure {
    content: '';
    position: absolute;
    display: inline-block;
    height: 7px;
    width: 12px;
    left: 0.4rem;
    transform: translateY(-1px) rotate(-45deg);
}

%Checkmark {
    @extend %CheckmarkStructure;
    @extend %CheckmarkSkin;
}

%CheckboxStructure {
    display: inline-block;

    input {
        & + label {
            display: flex;
            align-items: center;
            position: relative;
            padding: 0;

            // The box
            &:before {
                @extend %CheckmarkBox;
            }
        }
    }
}

%CheckboxSkin {
    font-weight: font-weight('regular');

    input {
        & + label {
            cursor: pointer;
        }
    }

    @at-root .Mode--light {
        .Checkbox {
            input:checked + label:before {
                border: 1px solid var(--grey);
            }
        }
    }
}

.Checkbox {
    @extend %CheckboxStructure;
    @extend %CheckboxSkin;

    input {
        position: absolute; // take it out of document flow
        opacity: 0; // hide it

        // DIFFERENT STATES
        // Box hover
        &:hover + label:before {
            border-width: 3px;
        }

        // Box focus
        &:focus + label:before {
            box-shadow: none;
        }

        // Box checked
        &:checked + label:before {
            background: var(--white);
            border: 3px solid var(--white);
        }
        // Checkmark
        &:checked + label:after {
            @extend %Checkmark;
        }

        // Disabled label
        &:disabled + label {
            cursor: default;
            color: $checkbox-color-disabled;
        }

        // Disabled AND checked
        &:disabled + label:before {
            border: 1px solid $checkbox-color-disabled;
            box-shadow: none;
        }

        // Disabled checkbox
        &:disabled:checked + label:before {
            background: $checkbox-color-disabled;
        }
    }
}
