$usermenu-list-offset: $internal-space-multiplier * 5;

%UserMenuStructure {
    min-width: 230px;
    width: auto;

    & > .Dropdown__list {
        left: unset;
        width: calc(100% - #{$usermenu-list-offset});
        margin-right: $usermenu-list-offset;

        @include for-screen('lg') {
            margin-right: 0;
            width: 100%;
        }
    }

    .UserMenu__label {
        display: none;
        margin-bottom: 0;

        .Textfield__input {
            padding-right: $internal-space-multiplier * 10;
            padding-left: $internal-space-multiplier * 3;
        }

        .Textfield__label {
            padding-left: $internal-space-multiplier;
        }

        .Icon {
            top: 12px;
        }

        @include for-screen('lg') {
            display: block;
        }
    }

    .UserMenu__avatar {
        display: flex;
        justify-content: flex-end;

        @include for-screen('lg') {
            display: none;
        }
    }
}

%UserMenuSkin {
    .UserMenu__avatar {
        > .Icon {
            border-radius: 50%;
        }

        &.is-active {
            .Icon {
                background-color: var(--grey-secondary);
                color: var(--secondary);
            }
        }
    }

    .UserMenu__link {
        color: var(--secondary);
        text-decoration: none;
        display: block;
        width: 100%;
    }

    .Dropdown__item:hover .UserMenu__link {
        color: var(--primary);
    }
}

.UserMenu {
    @extend %UserMenuStructure;
    @extend %UserMenuSkin;
}
