.IndexingFact {
    display: grid;
    grid-template-areas: 'icon value' 'description description';
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto;
    column-gap: map-get($x-axis, 1);
}

.IndexingFact__description {
    grid-area: description;
}
