/*
    LINK
*/
%LinkStructure {
    display: inline-block;
}

%LinkSkin {
    color: var(--accent);
    text-decoration: none;
    cursor: pointer;
}

.Link {
    @extend %LinkStructure;
    @extend %LinkSkin;
}
