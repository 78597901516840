%ContentLayoutStructure {
    display: grid;
    grid-gap: map-get($y-axis, 1);
    justify-items: self-start;
    align-content: start;
    width: 100%;
}

%ContentLayoutCenteredStructure {
    @extend %ContentLayoutStructure;
    justify-items: center;
}

.ContentLayout {
    @extend %ContentLayoutStructure;
}

.ContentLayout--centered {
    @extend %ContentLayoutCenteredStructure;
}
